/* eslint  react/forbid-prop-types: 0 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Footer from './Footer/Footer';
import { PortfolioProvider } from '../context/context';
import { footerData } from '../data/data';

function BlogPost({ html, title, description, category, date, topImg, tableOfContents }) {
  const [footer, setFooter] = useState({});

  useEffect(() => {
    setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider value={{ footer }}>
      <section id="projects">
        <div className="container">
          <div className="project-wrapper">
            <h1 className="blog-title">{title}</h1>
            <a href={`/categories/${category}/`}>{`カテゴリー - ${category}`}</a>
            <p style={{ color: '#aaa' }}>{`公開日: ${date}`}</p>
            <Img fluid={topImg} />
            <p>{description}</p>
            <div
              className="table-of-content"
              dangerouslySetInnerHTML={{ __html: tableOfContents }}
            />
            <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        </div>
      </section>
      <Footer />
    </PortfolioProvider>
  );
}

BlogPost.propTypes = {
  html: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  category: PropTypes.string,
  date: PropTypes.string,
  topImg: PropTypes.object,
  tableOfContents: PropTypes.string,
};

export default BlogPost;
