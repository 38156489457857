/* eslint  react/forbid-prop-types: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import BlogPost from '../components/BlogPost';
import Header from '../components/Header';
import { headData } from '../data/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

const BlogTemplate = ({ data }) => {
  const { markdownRemark, images } = data;
  const { frontmatter, html, tableOfContents } = markdownRemark;
  const { title, description, slug, category, img, date, createdAt, updatedAt } = frontmatter;
  const { title: siteTitle, lang, keyword, siteUrl } = headData;
  const image = images.edges.find((n) => n.node.relativePath.includes(img));
  const imageSrc = image.node.childImageSharp.fluid;

  return (
    <>
      <Header
        title={siteTitle}
        titleText={`${title} | ${siteTitle}`}
        lang={lang}
        description={description}
        keyword={keyword}
        baseUrl={siteUrl}
        siteUrl={`${siteUrl}${slug}`}
        ogpUrl={img}
        isBlogPost="true"
        createdAt={createdAt}
        updatedAt={updatedAt}
      />
      <BlogPost
        html={html}
        title={title}
        description={description}
        category={category}
        date={date}
        topImg={imageSrc}
        tableOfContents={tableOfContents}
      />
    </>
  );
};

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      tableOfContents(absolute: false, pathToSlugField: "frontmatter.path", maxDepth: 4)
      frontmatter {
        title
        description
        img
        slug
        category
        date(formatString: "YYYY/MM/DD")
      }
    }
    images: allFile {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid(maxWidth: 1366) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

BlogTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    images: PropTypes.object,
  }),
};

export default BlogTemplate;
